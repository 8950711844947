// extracted by mini-css-extract-plugin
export var appNameSection = "Testimonials-module--app-name-section--IJlrf";
export var appText = "Testimonials-module--app-text--Uv9gd";
export var appButton = "Testimonials-module--app-button--l6imQ";
export var appButtonElement = "Testimonials-module--app-button-element--fkXIM";
export var appContainer = "Testimonials-module--app-container--Xs6Ya";
export var container = "Testimonials-module--container--9doJZ";
export var testimonials__container = "Testimonials-module--testimonials__container--7B6s7";
export var appRow = "Testimonials-module--app-row--aE5PE";
export var appCol = "Testimonials-module--app-col--sn5B-";
export var appCol1 = "Testimonials-module--app-col-1--w0dlR";
export var appCol2 = "Testimonials-module--app-col-2--I6buc";
export var appCol3 = "Testimonials-module--app-col-3--dHGYX";
export var appCol4 = "Testimonials-module--app-col-4--HCtET";
export var appCol5 = "Testimonials-module--app-col-5--t8CGv";
export var appCol6 = "Testimonials-module--app-col-6--+4e65";
export var appCol7 = "Testimonials-module--app-col-7--Jwbc+";
export var appCol8 = "Testimonials-module--app-col-8--wGkuU";
export var appCol9 = "Testimonials-module--app-col-9--bM9Gh";
export var appCol10 = "Testimonials-module--app-col-10--YmEXk";
export var appCol11 = "Testimonials-module--app-col-11--z5Yce";
export var appCol12 = "Testimonials-module--app-col-12--qMHw1";
export var appColXl1 = "Testimonials-module--app-col-xl-1--7HCK7";
export var appColXl2 = "Testimonials-module--app-col-xl-2--sqiuV";
export var appColXl3 = "Testimonials-module--app-col-xl-3--qRLvN";
export var appColXl4 = "Testimonials-module--app-col-xl-4--DyW6j";
export var appColXl5 = "Testimonials-module--app-col-xl-5--oqGlH";
export var appColXl6 = "Testimonials-module--app-col-xl-6--ipVg3";
export var appColXl7 = "Testimonials-module--app-col-xl-7--NK0EB";
export var appColXl8 = "Testimonials-module--app-col-xl-8--lRIFM";
export var appColXl9 = "Testimonials-module--app-col-xl-9--tByNV";
export var appColXl10 = "Testimonials-module--app-col-xl-10--pRb1K";
export var appColXl11 = "Testimonials-module--app-col-xl-11--H7i09";
export var appColXl12 = "Testimonials-module--app-col-xl-12--+6Bui";
export var appColLg1 = "Testimonials-module--app-col-lg-1--uk9I7";
export var appColLg2 = "Testimonials-module--app-col-lg-2--VDjy4";
export var appColLg3 = "Testimonials-module--app-col-lg-3---Ypga";
export var appColLg4 = "Testimonials-module--app-col-lg-4--3QtgE";
export var appColLg5 = "Testimonials-module--app-col-lg-5--0p4sG";
export var appColLg6 = "Testimonials-module--app-col-lg-6--DCamX";
export var appColLg7 = "Testimonials-module--app-col-lg-7--V6Ymj";
export var appColLg8 = "Testimonials-module--app-col-lg-8--o2aF0";
export var appColLg9 = "Testimonials-module--app-col-lg-9--GVWJb";
export var appColLg10 = "Testimonials-module--app-col-lg-10--WKBBa";
export var appColLg11 = "Testimonials-module--app-col-lg-11--mWzvg";
export var appColLg12 = "Testimonials-module--app-col-lg-12--dFOAF";
export var appColMd1 = "Testimonials-module--app-col-md-1--Hwjt+";
export var appColMd2 = "Testimonials-module--app-col-md-2--XP8Y3";
export var appColMd3 = "Testimonials-module--app-col-md-3--WYxuT";
export var appColMd4 = "Testimonials-module--app-col-md-4--+9gLS";
export var appColMd5 = "Testimonials-module--app-col-md-5--Pkatb";
export var appColMd6 = "Testimonials-module--app-col-md-6--aDEO4";
export var appColMd7 = "Testimonials-module--app-col-md-7--nm4iL";
export var appColMd8 = "Testimonials-module--app-col-md-8--bB6+Q";
export var appColMd9 = "Testimonials-module--app-col-md-9--IwHk3";
export var appColMd10 = "Testimonials-module--app-col-md-10--185do";
export var appColMd11 = "Testimonials-module--app-col-md-11--1Pszh";
export var appColMd12 = "Testimonials-module--app-col-md-12--yMU6e";
export var appColSm1 = "Testimonials-module--app-col-sm-1--5zeiq";
export var appColSm2 = "Testimonials-module--app-col-sm-2--Qbp3y";
export var appColSm3 = "Testimonials-module--app-col-sm-3--icTQR";
export var appColSm4 = "Testimonials-module--app-col-sm-4--LJr1D";
export var appColSm5 = "Testimonials-module--app-col-sm-5--RjWnw";
export var appColSm6 = "Testimonials-module--app-col-sm-6--1rKJb";
export var appColSm7 = "Testimonials-module--app-col-sm-7--FMbNj";
export var appColSm8 = "Testimonials-module--app-col-sm-8--7MzkG";
export var appColSm9 = "Testimonials-module--app-col-sm-9--DQ5n1";
export var appColSm10 = "Testimonials-module--app-col-sm-10--KKhXS";
export var appColSm11 = "Testimonials-module--app-col-sm-11--6KS9v";
export var appColSm12 = "Testimonials-module--app-col-sm-12--a1BjJ";
export var appPseudo = "Testimonials-module--app-pseudo--LKxYy";
export var appFormLabel = "Testimonials-module--app-form-label--wq1s1";
export var appFormLabelText = "Testimonials-module--app-form-label-text--PFaNT";
export var appFormInput = "Testimonials-module--app-form-input--TSlxT";
export var appFormLabelTextFocus = "Testimonials-module--app-form-label-text-focus--jyttn";
export var appFormLabelError = "Testimonials-module--app-form-label-error--co6E3";
export var appFormError = "Testimonials-module--app-form-error--GlC1i";
export var testimonials = "Testimonials-module--testimonials--GzaiY";
export var testimonials__topBlock = "Testimonials-module--testimonials__topBlock--Ep4CI";
export var testimonials__title = "Testimonials-module--testimonials__title--jwXBn";
export var testimonials__subtitle = "Testimonials-module--testimonials__subtitle--3332+";
export var sliderWrapper = "Testimonials-module--sliderWrapper--Zpnsh";
export var sliderWrapper__sliderButtonPrev = "Testimonials-module--sliderWrapper__sliderButtonPrev--ptyDE";
export var sliderWrapper__sliderButtonNext = "Testimonials-module--sliderWrapper__sliderButtonNext--NtMS7";
export var sliderWrapper__slide = "Testimonials-module--sliderWrapper__slide--aK51x";
export var sliderWrapper__slideQuoteLeft = "Testimonials-module--sliderWrapper__slideQuoteLeft--kFZ2e";
export var sliderWrapper__slideQuoteRight = "Testimonials-module--sliderWrapper__slideQuoteRight--OlXIT";
export var slideText = "Testimonials-module--slideText--+id8g";
export var slideBottom = "Testimonials-module--slideBottom--hP1Mn";
export var slideBottom__line = "Testimonials-module--slideBottom__line--DLBd2";
export var slideBottom__signatureBox = "Testimonials-module--slideBottom__signatureBox--LPvlX";
export var slideBottom__name = "Testimonials-module--slideBottom__name--KcD1+";
export var slideBottom__details = "Testimonials-module--slideBottom__details--gv5Qi";