import React, { useState } from "react"
import * as classes from "./Testimonials.module.scss"

import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css"
import { StaticImage } from "gatsby-plugin-image"

import SliderArrowLeft from "./components/SliderArrowLeft"
import SliderArrowRight from "./components/SliderArrowRight"
import SlideQuoteLeft from "./components/SlideQuoteLeft"
import SlideQuoteRight from "./components/SlideQuoteRight"

export default function Testimonials() {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  return (
    <section className={classes.testimonials}>
      <div className={classes.testimonials__container}>
        <div className={classes.testimonials__topBlock}>
          <p className={classes.testimonials__subtitle}>Jak jest naprawdę?</p>
          <h2 className={classes.testimonials__title}>
            Poznaj opinie osób, które zdecydowały się na ekstra pieniądze!
          </h2>
        </div>
        <div className={classes.sliderWrapper}>
          <button
            className={classes.sliderWrapper__sliderButtonPrev}
            ref={node => setPrevEl(node)}
          >
            <SliderArrowLeft />
          </button>

          <Swiper
            style={{ width: "100%" }}
            modules={[Navigation]}
            navigation={{ prevEl, nextEl }}
            slidesPerView={1}
          >
            <SwiperSlide>
              <div className={classes.sliderWrapper__slide}>
                <div className={classes.sliderWrapper__slideQuoteLeft}>
                  <SlideQuoteLeft />
                </div>
                <div className={classes.sliderWrapper__slideQuoteRight}>
                  <SlideQuoteRight />
                </div>
                <p className={classes.slideText}>
                  Nie potrafię usiedzieć w&nbsp;miejscu. Lubię podejmować
                  ciekawe pracę, np&nbsp;jako kierowca czy barman. Reprezentant
                  ds.&nbsp;marki to ekstra praca dla takich wolnych duchów jak
                  ja, którzy lubią dobrą kasę i&nbsp;elastyczny grafik. Do tego
                  kontakt z&nbsp;ludźmi i&nbsp;możliwość budowania pozytywnego
                  wizerunku marki dają mi dużo satysfakcji.
                </p>
                <div className={classes.slideBottom}>
                  <div className={classes.slideBottom__line}></div>
                  <div className={classes.slideBottom__signatureBox}>
                    <h4 className={classes.slideBottom__name}>Asia</h4>
                    <h4 className={classes.slideBottom__details}>
                      35 lat, Mińsk Mazowiecki
                    </h4>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.sliderWrapper__slide}>
                <div className={classes.sliderWrapper__slideQuoteLeft}>
                  <SlideQuoteLeft />
                </div>
                <div className={classes.sliderWrapper__slideQuoteRight}>
                  <SlideQuoteRight />
                </div>
                <p className={classes.slideText}>
                  Dzięki pracy jako Reprezentantka ds.&nbsp;marki mogłam
                  pogodzić wychowanie malucha z&nbsp;dodatkowym zarobkiem. Bycie
                  mamą na&nbsp;cały etat to&nbsp;wymagające zadanie, dlatego
                  elastyczna i&nbsp;przyjemna to&nbsp;idealne rozwiązanie.
                </p>
                <div className={classes.slideBottom}>
                  <div className={classes.slideBottom__line}></div>
                  <div className={classes.slideBottom__signatureBox}>
                    <h4 className={classes.slideBottom__name}>Gosia</h4>
                    <h4 className={classes.slideBottom__details}>29 lat</h4>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.sliderWrapper__slide}>
                <div className={classes.sliderWrapper__slideQuoteLeft}>
                  <SlideQuoteLeft />
                </div>
                <div className={classes.sliderWrapper__slideQuoteRight}>
                  <SlideQuoteRight />
                </div>
                <p className={classes.slideText}>
                  Elastyczna praca i&nbsp;szansa na uzyskanie finansowego
                  bezpieczeństwa - to liczyło się dla mnie najbardziej, kiedy
                  szukałem dodatkowego źródła zarobku w&nbsp;czasie szalejącej
                  inflacji. Reprezentant ds.&nbsp;marki to świetny sposób na
                  podreperowanie domowego budżetu.
                </p>
                <div className={classes.slideBottom}>
                  <div className={classes.slideBottom__line}></div>
                  <div className={classes.slideBottom__signatureBox}>
                    <h4 className={classes.slideBottom__name}>Andrzej</h4>
                    <h4 className={classes.slideBottom__details}>40 lat</h4>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <button
            className={classes.sliderWrapper__sliderButtonNext}
            ref={node => setNextEl(node)}
          >
            <SliderArrowRight />
          </button>
        </div>
      </div>
    </section>
  )
}
