import React, {useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo/Seo";
import VerticalButton from '../components/VerticalButton/VerticalButton'
import Header from '../section/Header/Header'
import LookingFor from "../section/LookingFor/LookingFor";
import Offer from "../section/Offer/Offer";
import Testimonials from "../section/Testimonials/Testimonials";
import ApplicationSteps from "../section/ApplicationSteps/ApplicationSteps";
import Application from "../section/Application/Application";
import CookieConsent from "../components/CookieConsentReact/CookieConsent";

export default function Home() {
  const [lookingForOffsetTop, setLookingForOffsetTop] = useState(0);
  const [stepsOffsetTop, setStepsOffsetTop] = useState(0);

  const lookingForHandler = (top) => {
    setLookingForOffsetTop(top)
  }

  const stepsHandler = (top) => {
    setStepsOffsetTop(top)
  }
  
  return (

      <Layout>
        <Seo title='Reprezentant ds. marki'/>
        <Header />
        <LookingFor lookingForHandler={lookingForHandler}/>
        <Offer />
          <Testimonials />
        <ApplicationSteps stepsHandler={stepsHandler} />
        <Application />
        <VerticalButton lookingForOffsetTop={lookingForOffsetTop} stepsOffsetTop={stepsOffsetTop}/>
          <CookieConsent />
      </Layout>
  )
}
