import React from "react"

const SlideQuoteLeft = () => {
  return (
    <svg viewBox="0 0 220 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 0L0 -5.56363e-06L-5.56363e-06 100L100 0Z" fill="black" />
      <path d="M220 0L120 -5.56363e-06L120 100L220 0Z" fill="black" />
    </svg>
  )
}

export default SlideQuoteLeft
