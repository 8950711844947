import React from "react"

const SliderArrowRight = () => {
  return (
    <svg viewBox="0 0 91 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M65.7563 54.1597L65.7545 56.3779L67.9727 56.3779L69.0948 56.3779L71.3082 56.3779L71.3111 54.1644C71.3194 47.7388 73.4337 41.6452 77.0977 37.2067C80.7518 32.7802 85.6044 30.3973 90.5535 30.3879L92.7657 30.3837L92.7657 28.1716L92.7657 26.8123L92.7657 24.5932L90.5467 24.5959C83.8219 24.604 77.483 27.8467 72.8826 33.4197C68.292 38.9806 65.7626 46.444 65.7563 54.1597Z"
        strokeWidth="4.43269"
      />
      <path
        d="M67.9727 -1.39408L65.7545 -1.39408L65.7563 0.824082C65.7626 8.53976 68.292 16.0032 72.8825 21.5641C77.483 27.1371 83.8219 30.3798 90.5467 30.3879L92.7657 30.3905L92.7657 28.1715L92.7657 26.8122L92.7657 24.6001L90.5535 24.5959C85.6044 24.5865 80.7518 22.2036 77.0977 17.7771C73.4337 13.3386 71.3194 7.24496 71.3111 0.819402L71.3082 -1.39408L69.0948 -1.39408L67.9727 -1.39408Z"
        strokeWidth="4.43269"
      />
      <path
        d="M90.5493 30.2381L92.7657 30.2381L92.7657 28.0217L92.7657 26.8123L92.7657 24.5959L90.5493 24.5959L1.34229 24.5959L-0.874054 24.5959L-0.874054 26.8123L-0.874054 28.0217L-0.874054 30.2381L1.34229 30.2381L90.5493 30.2381Z"
        strokeWidth="4.43269"
      />
    </svg>
  )
}

export default SliderArrowRight
