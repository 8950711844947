import React from "react"

const SliderArrowLeft = () => {
  return (
    <svg viewBox="0 0 91 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5152 1.48482L25.517 -0.733337L23.2988 -0.733338L22.1767 -0.733338L19.9632 -0.733338L19.9604 1.48014C19.9521 7.9057 17.8378 13.9993 14.1738 18.4378C10.5197 22.8644 5.66705 25.2472 0.717965 25.2566L-1.49417 25.2608L-1.49417 27.473L-1.49417 28.8323L-1.49418 31.0513L0.724833 31.0486C7.44962 31.0405 13.7885 27.7978 18.3889 22.2249C22.9794 16.6639 25.5088 9.2005 25.5152 1.48482Z"
        strokeWidth="4.43269"
      />
      <path
        d="M23.2988 57.0386L25.517 57.0386L25.5152 54.8205C25.5089 47.1048 22.9794 39.6413 18.3889 34.0804C13.7885 28.5074 7.44962 25.2648 0.724835 25.2567L-1.49417 25.254L-1.49417 27.473L-1.49417 28.8323L-1.49417 31.0445L0.717967 31.0487C5.66706 31.058 10.5197 33.4409 14.1738 37.8674C17.8378 42.306 19.9521 48.3996 19.9604 54.8251L19.9633 57.0386L22.1767 57.0386L23.2988 57.0386Z"
        strokeWidth="4.43269"
      />
      <path
        d="M0.722168 25.4065L-1.49417 25.4065L-1.49417 27.6228L-1.49417 28.8323L-1.49418 31.0486L0.722168 31.0486L89.9292 31.0486L92.1455 31.0486L92.1455 28.8323L92.1455 27.6228L92.1455 25.4065L89.9292 25.4065L0.722168 25.4065Z"
        strokeWidth="4.43269"
      />
    </svg>
  )
}

export default SliderArrowLeft
