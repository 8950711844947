import React from "react"
import * as classes from "./Header.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import hero from "../../images/hero-main-v2-yellow3.jpg"

export default function Header() {
  return (
    <header className={classes.header}>
      <div className={classes.header__overlay} />

      <div className={classes.header__wrapper}>
        <div className={classes.header__left}>
          <div className={classes.header__logos}>
            <StaticImage
              className={classes.header__logo}
              src="../../images/logo-cursor.png"
              placeholder="blurred"
              quality={90}
              alt="Logo CURSOR"
              objectFit="contain"
            />
            <StaticImage
              className={classes.header__logo}
              src="../../images/logo-bat.png"
              placeholder="blurred"
              quality={90}
              alt="Logo British American Tobacco"
              objectFit="contain"
            />
          </div>
          <div className={classes.header__wrapper_content}>
            <div className={classes.header__extraBox}>
              <StaticImage
                className={classes.header__title_img}
                src="../../images/ekstra.png"
                placeholder="blurred"
                quality={90}
                alt="ekstra"
              />
            </div>
            <h1 className={classes.header__title}>
              Zostań Reprezentantem ds.&nbsp;marki i zarabiaj<br></br> pieniądze
            </h1>
            <p className={classes.header__text}>
              Dołącz do OEX Cursor dla BAT i zyskaj ekstra pracę!
            </p>
            <Link to="/#applicationSteps" className={classes.header__button}>
              Sprawdź i aplikuj!
            </Link>
          </div>
        </div>
        <div className={classes.header__graphic}>
          <StaticImage
            src="../../images/hero-graphic.svg"
            alt="abstrakcyjny element graficzny"
          />
        </div>
      </div>
      <img
        src={hero}
        alt="grupa ludzi robiących sobie zdjęcie"
        className={classes.header__bg}
      />
    </header>
  )
}
