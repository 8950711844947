import React, {useState, useEffect} from 'react';
import * as classes from './Form.module.scss';
import InputText from '../InputText/InputText'
import InputTextArea from '../InputTextArea/InputTextArea'
import InputSelect from '../InputSelect/InputSelect'
import InputFile from '../InputFile/InputFile'
import FormAgreement from '../FormAgreement/FormAgreement'
import InputCheckbox from '../InputCheckbox/InputCheckbox'
import Checkbox from '../Checkbox/Checkbox'
import InputSubmit from '../InputSubmit/InputSubmit'
import { FormProvider, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import FormMessage from '../FormMessage/FormMessage'
import axios from 'axios';
import terms from '/static/regulamin.pdf';

const POSTFORM_ENDPOINT = process.env.GATSBY_FORM_API;

const messages = {
    succes: {isSubmit: true, message: 'succes'},
    error: {isSubmit: true, message: 'error'}
}


const messageOne = "Wyrażam zgodę na przetwarzanie moich danych osobowych (podanych w formularzu rekrutacyjnym oraz załączonych dokumentach aplikacyjnych) przez OEX Cursor S.A. z siedzibą w Warszawie oraz wskazanych w klauzuli informacyjnej odbiorców, dla celów przyszłych rekrutacji na inne podobne stanowiska (zgoda obejmuje dodatkowe dane osobowe, które nie zostały wskazane w Kodeksie pracy lub w innych przepisach prawa, np. wizerunek, informacje dotyczące zainteresowań, itp.).";

const messageTwo = "Akceptuję regulamin dostepny tutaj: ";
const termsLink = <a href={terms} target="_blank"  rel="noreferrer" >Regulamin</a>;


export default function Form() {
    const [submitState, setSubmitState] = useState({isSubmit: false, message: ''});
    const [cities, setCities] = useState(null);
    const [sending, setSending] = useState(false);
    const [isCV, setIsCV] = useState(true);
    const methods = useForm();

    useEffect(() => {
        axios.get(`${POSTFORM_ENDPOINT}/erecruiter/cities/representant`).then((response) => {
            const responseCities = response.data;
            console.log(responseCities);
            setCities([...responseCities])
        })
    }, [])


    const onSubmit = (data) => {
        if(isCV) {
            const file  = new FormData()
            file.append('file', data.uploadedFile[0])
            setSending(true);
            axios.post(`${POSTFORM_ENDPOINT}/files`, file)
                .then(res => {
                    finalData(res.data.uuid, data)
                })
                .catch(error => {
                    setSending(false);
                    submitError(error)
                })
        } else {
            finalData(null, data)
        }
    }

    const finalData = (uuid, data) => {
        window.dataLayer = window.dataLayer || [];

        let resultData = {...data, regualtionsAccepted: true, uploadedFile: uuid}

        if (resultData.student === 'true') {
            resultData = {...resultData, student: true}
        }
        if (resultData.futureActionsConsent === 'true') {
            resultData = {...resultData, futureActionsConsent: true}
        }
        if (resultData.regualtions === 'true') {
            resultData = {...resultData, regualtions: true}
        }

        axios.post(`${POSTFORM_ENDPOINT}/contacts`, resultData)
        .then(res => {
            setSending(false);
            if  (res.status >= 200 && res.status < 300) {
                setSubmitState(messages.succes)
                if(window.dataLayer) {
                    window.dataLayer.push({'event': 'form_validation=true'})
                }
            } else {
                submitError()
            }
        })
        .catch(error => submitError(error))
    }

    const submitError = () => {
        setSubmitState(messages.error)
    }

    const reloadPage = () => {
        window.location.reload();
    }

    const sendMoreApplication = () => {
        setSubmitState({isSubmit: false, message: ''})
        setIsCV(true)
        methods.reset()
    }

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    return (
        <>
        {submitState.isSubmit
            ?<FormMessage message={submitState.message} succes={messages.succes.message} reloadPage={reloadPage} sendMoreApplication={sendMoreApplication}/>
            :<FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form} autoComplete="off">
                    <InputText name='firstName' label='imię' requirements = {{required: { value: true, message: "imię wymagane"}}} />
                    <InputText name='lastName' label='nazwisko' requirements = {{required: { value: true, message: "nazwisko wymagane"}}} />
                    <InputText name='email' label='e-mail' requirements = {{required: { value: true, message: "e-mail wymagany"}, pattern: { value: /^[a-z\d]+[\w\d.-]*@(?:[a-z\d]+[a-z\d-]+\.){1,5}[a-z]{2,6}$/i, message: 'nieprawidłowy e-mail' } }} />
                    <InputText name='phone' label='telefon' type='number' requirements = {{required: { value: true, message: "telefon wymagany"}}} />
                    {cities !== null && (<InputSelect values={cities} name="city" label="miasto" requirements = {{required: { value: true, message: "miasto wymagane"}}} />) }
                    <div className={classes.form__row}>
                        <InputFile name='uploadedFile' label='dołącz plik cv' requirements = {{required: { value: isCV, message: "zabrakło pliku"}}} isCV={isCV}/>
                        <Checkbox content={'nie mam CV'} setIsCV={setIsCV} half={true}/>
                    </div>
                    <div className={classes.form__row}>
                        {isCV || <InputCheckbox name='student' content={'Czy posiadasz status studenta?'} half={true}/>}
                        {isCV || <InputTextArea name='experienceDescription' label='Doświadczenie zawodowe' requirements = {{required: { value: true, message: "doświadczenie zawodowe wymagane"}}} />}
                    </div>
                    <InputText honeypot={true} name='login' label='login' />
                    <FormAgreement />
                    <InputCheckbox name='futureActionsConsent' requirements = {{required: { value: false, message: "zgoda wymagana"}}} content={messageOne}/>
                    <InputCheckbox name='regualtions' requirements = {{required: { value: true, message: "zgoda wymagana"}}} content={messageTwo} termsLink={termsLink}/>
                    <InputSubmit text={sending ? 'Czekaj' : 'Wyślij'} disabled={sending}/>
                    <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY} size="invisible" onChange={onChange}/>
                </form>
            </FormProvider>
        }
        </>
    )
}
