import React, { useRef, useEffect } from "react"
import * as classes from "./ApplicationSteps.module.scss"
import { StaticImage } from "gatsby-plugin-image"

export default function ApplicationSteps({ stepsHandler }) {
  const steps = useRef(null)

  useEffect(() => {
    stepsHandler(steps.current.offsetTop)
  }, [stepsHandler])
  return (
    <section
      ref={steps}
      className={classes.applicationSteps}
      id="applicationSteps"
    >
      <div className={classes.applicationSteps__row_img}>
        <div className={classes.applicationSteps__triangle}></div>
        <StaticImage
          className={classes.applicationSteps__img}
          src="../../images/aplicationSteps.jpg"
          placeholder="blurred"
          quality={95}
          alt="application"
        />
      </div>
      <div
        className={classes.applicationSteps__container}
        id="applicationSteps"
      >
        <div className={classes.applicationSteps__wrapper}>
          <h2 className={classes.applicationSteps__title}>
            Ścieżka aplikacji jest prosta!
          </h2>
          <div className={classes.applicationSteps__steps}>
            <div
              className={`${classes.applicationSteps__step} ${classes.step}`}
            >
              <p className={classes.step__number}>01</p>
              <p className={classes.step__title}>
                Wystarczy, że wypełnisz przygotowany dla Ciebie formularz
              </p>
            </div>
            <div
              className={`${classes.applicationSteps__step} ${classes.step}`}
            >
              <p className={classes.step__number}>02</p>
              <p className={classes.step__title}>
                Jeśli Twój profil spełnia nasze wymagania, wyślemy Ci mail
                z&nbsp;zaproszeniem na spotkanie rekrutacyjne
              </p>
            </div>
            <div
              className={`${classes.applicationSteps__step} ${classes.step}`}
            >
              <p className={classes.step__number}>03</p>
              <p className={classes.step__title}>
                Na spotkaniu po prostu bądź sobą!
              </p>
              <p className={classes.step__text}>
                Jeśli z&nbsp;jakichś względów nie zdecydujemy się z&nbsp;Tobą
                spotkać, też poinformujemy Cię o&nbsp;tym mailowo. Będziemy na
                Ciebie czekać w&nbsp;kolejnych rekrutacjach.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
