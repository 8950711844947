import React from "react"

const SlideQuoteRight = () => {
  return (
    <svg viewBox="0 0 220 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M120 100.098L220 100.098L220 0.0983855L120 100.098Z"
        fill="black"
      />
      <path d="M0 100.098L100 100.098L100 0.0983855L0 100.098Z" fill="black" />
    </svg>
  )
}

export default SlideQuoteRight
