import React, { useEffect, useRef, useState } from "react"
import * as classes from "./LookingFor.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import useWindowSize from "../../hooks/useWindowSize"

export default function LookingFor({ lookingForHandler }) {
  const section = useRef(null)
  const wrapper = useRef(null)
  const tl = useRef(null)
  const desc1 = useRef(null)
  const desc2 = useRef(null)
  const desc3 = useRef(null)
  const descs = useRef(null)
  const [timeline, setTimeline] = useState()
  const size = useWindowSize()

  useEffect(() => {
    const descsListHeight =
      desc1.current.clientHeight +
      desc2.current.clientHeight +
      desc3.current.clientHeight
    let descsScrollHeight

    if (size.height > 900) {
      descsScrollHeight = descsListHeight * 0.25
    } else if (size.height > 750 && size.height < 900) {
      descsScrollHeight = descsListHeight * 0.3
    } else if (size.height > 700 && size.height < 750) {
      descsScrollHeight = descsListHeight * 0.4
    } else if (size.height > 650 && size.height < 700) {
      descsScrollHeight = descsListHeight * 0.5
    } else if (size.height > 600 && size.height < 650) {
      descsScrollHeight = descsListHeight * 0.6
    } else if (size.height < 600) {
      descsScrollHeight = descsListHeight * 0.7
    }

    if (size.width > 1024) {
      gsap.registerPlugin(ScrollTrigger)
      lookingForHandler(section.current.offsetTop)
      createTimeline(descsScrollHeight)
      createScrollTrigger(tl.current)
    }
  }, [size])

  const createTimeline = descsScrollHeight => {
    const timeline = gsap.timeline().fromTo(
      descs.current,
      { top: "40%" },
      {
        top: -descsScrollHeight,
      }
    )
    tl.current = timeline
    setTimeline(timeline)
  }
  const createScrollTrigger = animation => {
    return ScrollTrigger.create({
      animation: animation,
      trigger: section.current,
      ease: "none",
      start: "top top",
      end: "bottom +=10%",
      toggleActions: "play none none reverse",
      preventOverlaps: true,
      fastScrollEnd: true,
      scrub: 1,
      pin: true,
      markers: false,
    })
  }
  return (
    <section ref={section} id="kogo-szukamy" className={classes.lookingFor}>
      <div className={classes.lookingFor__container}>
        <div className={classes.lookingFor__wrapper} ref={wrapper}>
          <div className={classes.lookingFor__left}>
            <h3 className={classes.lookingFor__nameSection}>Kogo szukamy?</h3>
            <h2 className={classes.lookingFor__title}>
              Reprezentant ds.&nbsp;marki
            </h2>
            <p className={classes.lookingFor__desc}>
              Pierwsze wrażenie jest nieocenione. Otwartość na&nbsp;innych
              ludzi, pozytywna energia i&nbsp;umiejętność zaciekawienia klienta
              są niezwykle istotne. Nasi Reprezentanci ds.&nbsp;marki to osoby
              na&nbsp;pierwszej linii kontaktu z&nbsp;klientem. To oni są
              zakupową inspiracją i&nbsp;tworzą pozytywny wizerunek produktu
              i&nbsp;samej marki. Chcesz być jednym z&nbsp;nich?
            </p>
          </div>
          <div className={classes.lookingFor__lists__desc} ref={descs}>
            <ul
              ref={desc1}
              className={`${classes.lookingFor__list} ${classes.list}`}
            >
              <p className={classes.list__title}>
                Twoja praca będzie polegała na:
              </p>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>sprzedaży produktów w&nbsp;wyznaczonych punktach</p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>budowaniu wizerunku marek na&nbsp;rynku</p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>
                  przekazywaniu informacji marketingowych o&nbsp;prezentowanych
                  produktach
                </p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>aktywnym pozyskiwaniu nowych konsumentów dla marki</p>
              </li>
            </ul>
            <ul
              ref={desc2}
              className={`${classes.lookingFor__list} ${classes.list}`}
            >
              <p className={classes.list__title}>Nasze wymagania:</p>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>
                  dyspozycyjność minimum 15 akcji w&nbsp;miesiącu
                  po&nbsp;5&nbsp;h (w&nbsp;tym weekendy)
                </p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>
                  przebojowość, energiczność i&nbsp;otwartość na&nbsp;nowe
                  doświadczenia
                </p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>łatwość nawiązywania kontaktów z&nbsp;innymi ludźmi</p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>pełnoletność</p>
              </li>
              <p className={classes.list__title}>Dodatkowo mile widziane są:</p>

              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>doświadczenie w&nbsp;sprzedaży</p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>prawo jazdy kat.&nbsp;B</p>
              </li>
            </ul>
            <ul
              ref={desc3}
              className={`${classes.lookingFor__list} ${classes.list} ${classes.list_last}`}
            >
              <p className={classes.list__title}>
                Poznaj mocne strony stanowiska:
              </p>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>elastyczny czas pracy</p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>wysokie zarobki</p>
              </li>
              <li>
                <StaticImage
                  className={classes.list__img}
                  src="../../images/arrow-right.svg"
                  placeholder="blurred"
                  quality={95}
                  alt="arrow"
                />
                <p>program rekomendacji pracowników</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
